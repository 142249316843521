import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "subordination", "regional", "agency" ]

  connect() {
    this.processSubordination(this.subordinationTarget.value)
  }

  doChangeSubordination(event) {
    this.processSubordination(event.target.value)
  }

  processSubordination(sub) {
    if (sub === 'federal') { // federal
      if (this.hasRegionalTarget) {
        this.regionalTarget.classList.add('d-none')
      }
      if (this.hasAgencyTarget) {
        //this.agencyTarget.classList.remove('d-none')

        this.agencyTarget.dataset.select2AutocompleteParams = this.agencyTarget.dataset.federalParams
      }
    } else if (sub === 'regional') { // regional
      if (this.hasRegionalTarget) {
        this.regionalTarget.classList.remove('d-none')
      }
      if (this.hasAgencyTarget) {
        //this.agencyTarget.classList.add('d-none')
        this.agencyTarget.dataset.select2AutocompleteParams = this.agencyTarget.dataset.regionalParams
      }
    } else {
      if (this.hasRegionalTarget) {
        this.regionalTarget.classList.add('d-none')
      }
      // if (this.hasAgencyTarget) {
      //   this.agencyTarget.classList.add('d-none')
      // }
    }
  }
}
