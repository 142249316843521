import { Controller } from "stimulus"
import * as echarts from 'echarts'
import $ from "jquery"

export default class extends Controller {
  connect() {
    let info = JSON.parse(this.data.get('info')) || []
    let title = this.data.get("title") || ''
    let imageField = this.data.get("imageField") || ''

    //не работает перенос строки
    if (title === 'order_percent') {
      title = "% организаций, оснащенных\n в соответствии с приказом № 1144н"
    }

    setTimeout(() => {
      this.chart = echarts.init(this.element)
    }, 75)

    let opts = {
      title: {
        //top: '-5%',
        text: title,
        left: 'center',
        textStyle: {
          fontSize: 12,

        }

      },
      toolbox: {
        feature: {
          saveAsImage : {
            show: true,
            type: 'png',
          }
        }
      },
      tooltip: {
        trigger: 'item',
        formatter: function (el) {
          var value = echarts.format.encodeHTML(el.data.tooltip_value);
          return [
            '<div class="tooltip-title">' +
            echarts.format.encodeHTML(el.data.name) +
            ': <strong>' + value + '</strong></div>',

          ].join('');
        }
      },
      legend: {
        top: '15%',
        orient: 'vertical',
        left: 'left'
      },
      series: [
        {
          top: '5%',
          left: '30%',
          name: '',
          type: 'pie',
          radius: ['20%', '80%'],
          labelLine: {
            show: false
          },
          label: {
            show: false,
            //position: 'center'
          },
          data: info,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          color: ['#91cc75','#5470c6',  '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
          itemStyle : {
            normal: {
              label: {
                show: true, position: 'inner',
                formatter: function (el) {
                  return `${el.value}%\n\n${el.data.tooltip_value}`
                },
              },
              labelLine: {
                show: false
              }
            },
          },
        }
      ]
    };

    setTimeout(() => {
      this.chart.setOption(opts)

    }, 75)
    setTimeout(() => {
      const img = new Image();
      img.src = this.chart.getDataURL({
        type: 'png',
        pixelRatio: 1.2,
        backgroundColor: '#fff',
        excludeComponents: ['toolbox']
      });
      $('#' + imageField).val(img.src)
    }, 2000);
    //console.log(info)

  }

  disconnect() {
    this.chart.dispose()
  }

  layout() {
    this.chart.resize()
  }
}
