import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  static targets = [ "organization", "agency", "nominations" ]

  connect() {
    this.toggleOrg(
      this.data.has('roles') && (
        this.data.get('roles') === 'med_org' ||
        this.data.get('roles') === 'self_promoted'
      )
    )
    this.toggleAgency(
      this.data.has('roles') && this.data.get('roles') === 'agency'
    )
    this.toggleNominations(
      this.data.has('roles') && this.data.get('roles') === 'work_group'
    )
  }

  doRoleSelect(event) {
    const roleId = event.target.value
    this.toggleOrg(roleId === '2' || roleId === '3')
    this.toggleAgency(roleId === '4')
    this.toggleNominations(roleId === '5')
  }

  toggleOrg(isOrg) {
    if (isOrg) {
      $(this.organizationTarget).show()
    } else {
      $(this.organizationTarget).hide()
    }
  }

  toggleAgency(isAgency) {
    if (isAgency) {
      $(this.agencyTarget).show()
    } else {
      $(this.agencyTarget).hide()
    }
  }

  toggleNominations(isNominations) {
    if (isNominations) {
      $(this.nominationsTarget).show()
    } else {
      $(this.nominationsTarget).hide()
    }
  }
}
