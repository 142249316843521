import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = [ "emailField" ]

  doEmailFieldToggle(event) {
    if (event.target.checked) {
      if (this.hasEmailFieldTarget) {
        $(this.emailFieldTarget).find('input').val('')
        this.emailFieldTarget.classList.add('d-none')
      }
    } else {
      if (this.hasEmailFieldTarget) {
        this.emailFieldTarget.classList.remove('d-none')
      }
    }
  }
}
