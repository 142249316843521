import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'

import $ from "jquery"

export default class extends Controller {

  static targets = [ "detail" ]
  connect() {
    StimulusReflex.register(this)
  }
  sendRegion(event){

    const regionId = event.target.value
    const orgId = this.data.get('organization')
    const isPublic = this.data.get('public')

    if (!regionId) { return }

    $('#agencydiv select').attr('data-controller', '');

    this.stimulate('Organizations::AgencyReflex#details', { serializeForm: false }, regionId, orgId,  isPublic)
  }

}
