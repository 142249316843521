import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "head" ]
  selectHead(e) {
    this.headTargets.forEach((h) => {
      if (h !== e.target) {
        h.checked = false
      }
    })
  }
}
