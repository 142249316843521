import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from "jquery"

export default class extends Controller {
  static targets = [ "destroyFlag" ]

  connect() {
    StimulusReflex.register(this)
  }

  markDestroy(e) {
    e.preventDefault()
    this.destroyFlagTarget.value = "1"
    $(this.element).hide(250)
  }
}
